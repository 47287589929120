import { useEffect } from "react";

// These must match themes in tailwind.config.js
type Brand = "byda" | "undermaps";

export const useBrandTheme = (brand: Brand) => {
  const root = document.getElementById("root");

  useEffect(() => {
    root?.classList.add(brand);
    return () => {
      root?.classList.remove(brand);
    };
  }, [brand, root?.classList]);
};
