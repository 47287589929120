import { FC } from "react";

export const RevealIcon: FC<{ className?: string; color?: string }> = ({
  className = "h-6 w-6",
  color = "#FF0066"
}) => (
  <svg
    className={className}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.580566 0.0319824H4.08239C5.01115 0.0343856 5.90102 0.406905 6.5567 1.06778C7.21238 1.72866 7.58029 2.6239 7.57969 3.55702H0.580566V0.0319824Z"
      fill={color}
    />
    <path
      d="M7.57959 3.55688H14.5787V3.71113C14.579 4.15349 14.4926 4.59158 14.3244 5.00038C14.1561 5.40919 13.9094 5.7807 13.5983 6.0937C13.2872 6.40671 12.9177 6.65508 12.5111 6.82464C12.1044 6.9942 11.6685 7.08163 11.2282 7.08192H10.9414C10.5004 7.08312 10.0634 6.99687 9.65559 6.82812C9.24777 6.65937 8.87709 6.41143 8.5648 6.09852C8.2525 5.7856 8.00473 5.41386 7.83567 5.00458C7.66661 4.5953 7.57959 4.15653 7.57959 3.7134V3.55915V3.55688Z"
      fill={color}
    />
    <path
      d="M18.0872 0.0319832H21.6206V3.55702H14.5786C14.5783 3.09402 14.6689 2.63551 14.8451 2.2077C15.0213 1.77988 15.2797 1.39117 15.6056 1.06378C15.9315 0.736389 16.3184 0.476747 16.7442 0.299703C17.17 0.122659 17.6264 0.0316849 18.0872 0.0319832Z"
      fill={color}
    />
    <path
      d="M0.580566 7.08203H4.08239C5.01076 7.08443 5.90029 7.45664 6.5559 8.11703C7.21152 8.77742 7.57969 9.67207 7.57969 10.6048H0.580566V7.08203Z"
      fill={color}
    />
    <path
      d="M7.59326 10.6047H14.5788V10.7613C14.5788 11.2033 14.4922 11.641 14.3238 12.0495C14.1554 12.4579 13.9086 12.829 13.5975 13.1416C13.2864 13.4541 12.917 13.7021 12.5105 13.8713C12.104 14.0404 11.6683 14.1275 11.2283 14.1275H10.9461C10.5059 14.1278 10.0699 14.041 9.66317 13.8719C9.25641 13.7029 8.88678 13.455 8.57541 13.1424C8.26405 12.8297 8.01704 12.4585 7.84852 12.05C7.68 11.6414 7.59326 11.2035 7.59326 10.7613V10.6047Z"
      fill={color}
    />
    <path
      d="M18.0872 7.08203H21.6206V10.6048H14.5786C14.5786 10.142 14.6694 9.68372 14.8457 9.25617C15.0221 8.82862 15.2806 8.44017 15.6064 8.11302C15.9322 7.78587 16.319 7.52644 16.7447 7.34954C17.1704 7.17263 17.6266 7.08173 18.0872 7.08203Z"
      fill={color}
    />
    <path
      d="M0.580566 14.0957H4.08239C5.01154 14.0981 5.90176 14.4709 6.5575 15.1323C7.21324 15.7937 7.58089 16.6895 7.57969 17.623H0.580566V14.0957Z"
      fill={color}
    />
    <path
      d="M7.57959 17.623H14.5787V17.7773C14.5787 18.6707 14.2255 19.5275 13.5967 20.1592C12.9679 20.7909 12.1151 21.1458 11.2259 21.1458H10.9414C10.5006 21.147 10.0638 21.0608 9.65611 20.8922C9.24844 20.7236 8.87786 20.4759 8.56559 20.1632C8.25333 19.8505 8.00551 19.4791 7.83632 19.07C7.66713 18.661 7.57989 18.2225 7.57959 17.7796V17.6253V17.623Z"
      fill={color}
    />
    <path
      d="M18.0872 14.0957H21.6206V17.6185H14.5786C14.5786 17.1557 14.6694 16.6974 14.8457 16.2698C15.0221 15.8423 15.2806 15.4538 15.6064 15.1267C15.9322 14.7995 16.319 14.5401 16.7447 14.3632C17.1704 14.1863 17.6266 14.0954 18.0872 14.0957Z"
      fill={color}
    />
  </svg>
);
